<template>
  <v-dialog
    :width="600"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        v-on="on"
        @click="
          fetchBoxes();
          fetchTattooerTokens();
        "
        block
        height="30px"
        :disabled="disabled"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card id="Token">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        <span>{{ $t("boxes.boxs") }}</span>
        <span v-if="tattooer.user.first_name" style="font-size: 15px">
          <br />{{ tattooer.user.first_name }}
          {{ tattooer.user.last_name }}
        </span>
      </v-card-title>
      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            action = false;
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <base-data-table-cards
        :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :headers_small="headers_small"
        :data="items"
        :loading="loading"
        :totalItems="totalBoxes"
        @update="fetchBoxes"
        :title="$t('Boxes')"
        v-if="action == false"
      >
        <!-- :sort-by.sync="sortBy"
      :server-items-length="totalCustomers"-->
        <template v-slot:item_name="item">
          <span>{{ item.name }}</span>
        </template>
        <template v-slot:item_user="item">
          <span v-if="item.user == null">-</span>
          <span
            v-else
            :style="
              item.tattooer_id == tattooer.id
                ? 'color: var(--v-primary-base) !important'
                : ''
            "
            >{{ item.user.first_name }} {{ item.user.last_name }}</span
          >
        </template>
        <template v-slot:item_actions="item">
          <template>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" large
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  class="cusList"
                  @click="
                    $router.push({
                      name: 'boxView',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -5px !important"
                      >$eye</v-icon
                    ></v-list-item-icon
                  ><v-list-item-title>
                    <span>{{
                      $t("view", { name: $tc("box") })
                    }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item class="cusList" @click="edit(item)">
                  <v-list-item-icon>
                    <v-icon style="margin-top: -5px !important" small
                      >mdi-plus</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{ $t("boxes.reservar") }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  class="cusList"
                  @click="save(item)"
                  v-if="item.tattooer_id != tattooer.id"
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -5px !important"
                      >$refresh</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{
                      $t("boxes.associate_tattooer")
                    }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  class="cusList"
                  @click="save(item, 'dis')"
                  v-if="item.tattooer_id == tattooer.id"
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -5px !important"
                      >$refresh</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{
                      $t("boxes.disassociate_tattooer")
                    }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>

        <template v-slot:item_status="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  $router.push({
                    name: 'boxList',
                    params: { btn: 'day' },
                  })
                "
                v-if="item.status != null"
                v-on="on"
                v-bind="attrs"
                :style="
                  boxesHoursDay(item.id).percentage >= 0.5
                    ? boxesHoursDay(item.id).percentage >= 0.75
                      ? boxesHoursDay(item.id).percentage == 1
                        ? 'color:red; cursor:pointer'
                        : 'color:orange; cursor:pointer'
                      : 'color:yellow; cursor:pointer'
                    : 'cursor:pointer'
                "
                >{{ $n(boxesHoursDay(item.id).percentage, "percent") }} /
                {{ boxesHoursDay(item.id).hour }}h
              </span>
            </template>
            <span
              >{{ $n(boxesHoursDay(item.id).percentage, "percent") }}
              {{ $t("ocupation") }} / {{ boxesHoursDay(item.id).hour }}
              {{ $t("hours_available") }}</span
            >
          </v-tooltip>
        </template>
      </base-data-table-cards>

      <form
        v-else
        action
        data-vv-scope="boxes-form"
        @submit.prevent="validateForm('boxes-form')"
      >
        <v-row style="width: 100%; margin: 0 auto">
          <v-row style="width: 100%; margin: 0 auto">
            <v-col
              cols="12"
              style="
              text-align: center;
              text-transform: uppercase;
              font-size: 16px;
            "
            >
              {{ box.name }}
            </v-col>
            <v-col id="time" cols="12" md="4" class="py-md-3 pb-0 pt-2">
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="fecha"
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$clock' : ''"
                    outlined
                    hide-details
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="startDate"
                    :label="$t('events.date')"
                    readonly
                    v-bind="attrs"
                    v-bind:class="{
                      'error--text': horarioError,
                    }"
                    v-on="on"
                    :value="
                      box.date ? $d(new Date(box.date), 'date2digits') : ''
                    "
                  />
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="box.date"
                  no-title
                  scrollable
                  color="primary"
                  @input="menu = false"
                  :allowed-dates="allowedDates"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5">
              <v-select
                :items="hours"
                v-model="box.start"
                :label="$t('events.startTime')"
                dense
                outlined
                :disabled="box.all_day"
                hide-details
                data-vv-validate-on="blur"
                v-validate="box.all_day ? '' : 'required'"
                :error-messages="errors.collect('boxes-form.startTime')"
                data-vv-name="startTime"
                style="display: inline-block; width: 45%"
              ></v-select>
              <p
                class="pa-1"
                style="display: inline-block; width: 10%; text-align: center"
              >
                -
              </p>
              <v-select
                :items="hours"
                v-model="box.end"
                :label="$t('events.endTime')"
                hide-details
                dense
                :disabled="box.all_day"
                data-vv-validate-on="blur"
                v-validate="box.all_day ? '' : 'required'"
                data-vv-name="endTime"
                :error-messages="errors.collect('boxes-form.endTime')"
                outlined
                style="display: inline-block; width: 45%"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              class="pa-0 mb-3 d-flex align-center "
            >
              <v-switch v-model="box.all_day" hide-details class="py-0 mt-0">
                <template v-slot:label>
                  <div class="no-wrap">{{ $t("alert.allDay") }}</div>
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row
            v-if="dateError || horarioError"
            class="py-0"
            style="width: 100%; margin: 0 auto"
          >
            <v-col
              class="login_error white--text pa-1 text-center rounded-xl my-0"
              >Está fuera del horario establecido</v-col
            >
          </v-row>
          <v-row
            style="width: 100%; margin: 0 auto"
            class="pt-3 mb-3 pr-3"
            justify="end"
          >
            <v-btn
              outlined
              @click="action = false"
              class="mr-3"
              style="height: 30px; width: 100px"
              elevation="0"
              >{{ $t("back") }}</v-btn
            >
            <v-btn
              type="submit"
              elevation="0"
              style="height: 30px; width: 100px !important"
              ><v-icon small style="margin-right: 5px">$save</v-icon
              >{{ $t("save", { name: "" }) }}</v-btn
            >
          </v-row>
        </v-row>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Boxes",
  props: ["tattooer", "list", "disabled"],
  watch: {
    options: {
      handler() {
        this.fetchBoxes();
      },
      deep: true,
    },
  },
  computed: {
    timeError() {
      if (this.box.start != undefined && this.box.end != undefined) {
        let s = this.box.start.split(":");
        let e = this.box.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] >= e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
    horarioError() {
      if (this.box.date && this.box.start && this.box.end) {
        let dh = this.timeStudio[this.dayOfWeek(this.box.date)];
        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.box.start && h[1] >= this.box.end) error = false;
        });
        return error;
      }
      return false;
    },
    hours() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        //console.log(hours);
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
        return hours;
      }
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
    },
    headers() {
      let array = [
        {
          text: this.$t("box"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("boxes.tattooer_associate"),
          align: "center",
          sortable: false,
          value: "user",
        },
      ];

      //TATTOOER ASSOCIATE

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        sortable: false,
        value: "status",
      });
      array.push({
        text: this.$t("actions"),
        align: "center",
        sortable: false,
        value: "actions",
      });
      return array;
    },
    headers_small() {
      let array = [
        {
          text: this.$t("box"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("boxes.tattooer_associate"),
          align: "center",
          sortable: false,
          value: "user",
        },
      ];

      //TATTOOER ASSOCIATE

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        sortable: false,
        value: "status",
      });
      return array;
    },
  },
  data() {
    return {
      dialog: false,
      action: false,
      statusList: [],
      menu: false,
      totalBoxes: 0,
      box: {
        id: null,
        name: null,
        status: null,
        start: null,
        end: null,
        date: new Date().toISOString().substr(0, 10),
        studio_id: this.$store.state.auth.user.studio.id,
      },
      items: [],
      options: {},
      filters: {
        search: "",
        dates: "",
        tattooers: "",
      },
      timeStudio: [],
    };
  },

  mounted() {
    //this.fetchBoxes();
    this.boxesHoursDay();
    this.fetchStatusList();
    this.fetchTimeStudio();
    console.log(this.tattooer);
  },
  methods: {
    allowedDates(date) {
      if (this.timeStudio && this.timeStudio.length > 0) {
        let a = this.timeStudio[this.dayOfWeek(date)];
        if (a) {
          return a.length > 0;
        }
      }
      return false;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    ...mapActions("boxes", ["getBoxes", "getTable"]),
    ...mapActions("studios", ["getStudio"]),
    ...mapActions("tattooers", ["editTattooerBox", "reservationBox"]),
    fetchTimeStudio() {
      this.getStudio(this.box.studio_id).then((data) => {
        //console.log(data);
        this.timeStudio = data.timetable;
        //console.log(this.timeStudio);
      });
    },
    fetchBoxes() {
      this.loading = true;
      this.getBoxes({
        pagination: this.options,
        filters: this.filters,
      }).then((boxes) => {
        //console.log(boxes.data);
        this.totalBoxes = boxes.total;
        this.items = boxes.data;

        this.loading = false;
      });
    },
    fetchStatusList() {
      this.loading = true;

      this.getTable({ date: this.today, studio: this.box.studio_id }).then(
        (boxes) => {
          //console.log(boxes.box);
          this.statusList = boxes.box;

          this.loading = false;
        }
      );
    },
    edit(value) {
      this.action = true;
      this.box.status = value.status;
      this.box.id = value.id;
      this.box.name = value.name;
      this.box.price = value.price;
    },
    save(value, dis) {
      this.box.id = value.id;
      this.box.status = value.status;
      //console.log(value);
      if (dis == "dis") {
        this.$confirm(
          this.$t("delete_disassociate_question", {
            name: value.user.first_name + " " + value.user.last_name,
            box: value.name,
          }),
          "",
          ""
        ).then(() => {
          this.editTattooerBox({
            tattooer_id: this.tattooer.id,
            formData: this.box,
          }).then((data) => {
            //console.log(data);
            this.$alert(this.$t("boxes.associate"), "", "");
            this.fetchBoxes();
            this.dialog = false;
            this.$emit("update");
          });
        });
      } else {
        this.editTattooerBox({
          tattooer_id: this.tattooer.id,
          formData: this.box,
        }).then((data) => {
          //console.log(data);
          this.$alert(this.$t("boxes.associate"), "", "");
          this.fetchBoxes();
          this.dialog = false;
          this.$emit("update");
        });
      }
    },
    boxesHoursDay(box) {
      let n = this.statusList.find((element) => element.id == box);

      if (n) {
        let hs = n.boxes_tattooer.filter((x) => x.start_date == this.box.date);
        if (hs) {
          //console.log(hs);
          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == end) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = [];
          let s = 0;

          hs.filter((k) => {
            for (let i = 0; i < hours.length; i++) {
              if (hours[i] == k.start_time) {
                s = i;
              } else if (hours[i] == k.end_time) {
                span.push(i - s);
              }
            }
          });
          let p = null;
          for (let index = 0; index < span.length; index++) {
            p += span[index];
          }

          let percentage = p / 2 / (end - start);
          let hour = p / 2 - (end - start);

          return { percentage: percentage, hour: Math.abs(hour) };
        }
      }
      return { percentage: null };
    },

    async validateForm(scope) {
      this.errors.clear();
      console.log("validando");
      this.$validator.validateAll(scope).then((data) => {
        if (data) {
          this.reservationBox({
            formData: this.box,
            tattooer_id: this.tattooer.id,
          }).then((data) => {
            //console.log(data);
            this.$alert("Guardado correctamente", "", "");
            this.fetchBoxes();
            this.action = false;
            this.dialog = false;
            this.$emit("update");
          });
          if (data.sentencia)
            this.$alert("El artista asignado está ocupado en este horario");
          else this.$alert("No hay disponibilidad para esta fecha", "", "");
        } else {
          console.log(data);
        }
      });
    },
    // reservation() {
    // //console.log(this.box)
    //   this.reservationBox({
    //     tattooer_id: this.tattooer.id,
    //     formData: this.box,
    //   }).then((data) => {
    //     //console.log(data);
    //     if (data.count === 0) {
    //       this.$alert("Guardado correctamente", "", "");
    //       this.fetchBoxes();
    //       this.dialog = false;
    //       this.action = false;
    //       this.$emit("update");
    //     } else {
    //       console.log(data);
    //       if (data.sentencia)
    //         this.$alert("El artista asignado está ocupado en este horario");
    //       else this.$alert("No hay disponibilidad para esta fecha", "", "");
    //     }
    //   });
    // },
  },
};
</script>

<style lang="sass">
.cusList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.cusList .v-icon, .cusList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)
#time
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
    margin-top: 8px !important
#Token
  width: 100%
  margin: 0
</style>
